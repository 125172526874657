var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.checkVisiable)?_c('div',[_c('div',{staticClass:"card"},[_c('el-page-header',{attrs:{"content":_vm.activeName === 'first'
            ? _vm.userInfo.hierarchy_type_id === 30
              ? '配货单详情'
              : '调拨单详情'
            : '调入本公司详情'},on:{"back":_vm.goBack}})],1),_c('Detail',{attrs:{"showPrint":_vm.showPrint,"arr":_vm.arr,"rowList1":_vm.rowList1,"tableData1":_vm.tableData1,"rowList2":_vm.rowList2,"tableData2":_vm.tableData2,"type":_vm.activeName !== 'first' ? 'transferDetails' : '',"exportUrl":'/store/goods.Allot/detailExport',"exportData":{
        allot_id: this.propObj.value,
        allot_to: this.allot_to,
      },"exportName":"调拨单详情导出文件"},on:{"onPrint":_vm.handlePrint,"onChangePage1":_vm.handlePage,"onChangePage2":_vm.handlePage2,"onSizeChange":_vm.handleSizeChange,"onSizeChange2":_vm.handleSizeChange2,"transferCheck":_vm.handleTransferCheck}})],1):_vm._e(),(_vm.checkVisiable === true)?_c('Check',{attrs:{"allot_id":this.propObj.value},on:{"hide":_vm.getDetail}}):_vm._e(),_c('PrintConf',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"function_code":"allotExport"},on:{"onRowList":_vm.handlePrintRow}}),_c('LocalPrint',{attrs:{"show":false,"printRows":_vm.printRows,"printData":_vm.printData,"title":_vm.title,"dialogVisible":_vm.localPrintVisible},on:{"onClose":function($event){_vm.localPrintVisible = false}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }