<template>
  <div>
    <div v-if="!checkVisiable">
      <div class="card">
        <el-page-header
          @back="goBack"
          :content="
            activeName === 'first'
              ? userInfo.hierarchy_type_id === 30
                ? '配货单详情'
                : '调拨单详情'
              : '调入本公司详情'
          "
        >
        </el-page-header>
      </div>
      <Detail
        @onPrint="handlePrint"
        :showPrint="showPrint"
        :arr="arr"
        :rowList1="rowList1"
        :tableData1="tableData1"
        :rowList2="rowList2"
        :tableData2="tableData2"
        :type="activeName !== 'first' ? 'transferDetails' : ''"
        @onChangePage1="handlePage"
        @onChangePage2="handlePage2"
        @onSizeChange="handleSizeChange"
        @onSizeChange2="handleSizeChange2"
        @transferCheck="handleTransferCheck"
        :exportUrl="'/store/goods.Allot/detailExport'"
        :exportData="{
          allot_id: this.propObj.value,
          allot_to: this.allot_to,
        }"
        exportName="调拨单详情导出文件"
      />
    </div>
    <Check
      v-if="checkVisiable === true"
      :allot_id="this.propObj.value"
      @hide="getDetail"
    />
    <PrintConf function_code="allotExport" @onRowList="handlePrintRow" v-show="false" />
    <LocalPrint :show="false" :printRows="printRows" :printData="printData" :title="title" :dialogVisible="localPrintVisible" @onClose="localPrintVisible = false" />
  </div>
</template>

<script>
import storage from "good-storage";
import Detail from "@/components/goods/detail/Index.vue";
import Check from "./Check.vue";
import {
  detailReq,
  detailGoodsListReq,
  getLogListReq,
} from "@/api/goods/goodsTransfer/detail.js";
import {
  allotPrintReq,
} from "@/api/goods/goodsTransfer/list";
import PrintConf from "@/components/printConf/Index.vue";
import LocalPrint from "@/components/localPrint/Index.vue";

export default {
  data() {
    return {
      localPrintVisible: false,
      printData: {},
      printRows: [],
      page: 1,
      page_num: this.$store.state.pageSizes[0],
      page2: 1,
      page_num2: this.$store.state.pageSizes[0],
      userInfo: {},
      allot_status: 0,
      allot_id: 0,
      checkVisiable: false,
      arr: [
        {
          id: 0,
          name: "调拨单号",
          value: "",
          prop: "allot_number",
        },
        {
          id: 1,
          name: "创建人",
          value: "",
          prop: "create_store_user_name",
        },
        {
          id: 2,
          name: "创建时间",
          value: "",
          prop: "create_time",
        },
        {
          id: 3,
          name: "总数量",
          value: "",
          prop: "allot_total_num",
        },
        {
          id: 4,
          name: "总净金重",
          value: "",
          prop: "total_goldweight",
        },
        {
          id: 5,
          name: "总重",
          value: "",
          prop: "total_weight",
        },
        {
          id: 6,
          name: "总成本价",
          value: "",
          prop: "cost_price",
        },
        {
          id: 7,
          name: "总标签价",
          value: "",
          prop: "total_sale_price",
        },
        {
          id: 8,
          name: "总回收价",
          value: "",
          prop: "total_recycle_price",
        },
        // {
        //   id: 9,
        //   name: "总标签价",
        //   value: "",
        //   prop: "total_sale_price",
        // },
        {
          id: 10,
          name: "状态",
          value: "",
          prop: "allot_status_text",
        },
        {
          id: 11,
          name: "审核人员",
          value: "",
          prop: "audit_store_user_name",
        },
        {
          id: 12,
          name: "审核时间",
          value: "",
          prop: "audit_time_text",
        },
        {
          id: 13,
          name: "调出门店",
          value: "",
          prop: "at_hierarchy_name",
        },
        {
          id: 14,
          name: "调入门店",
          value: "",
          prop: "to_hierarchy_name",
        },
        {
          id: 15,
          name: "调入仓库",
          value: "",
          prop: "to_warehouse_text",
        },
        {
          id: 16,
          name: "未通过原因",
          value: "",
          prop: "audit_content",
        },
        {
          id: 17,
          name: "签收人员",
          value: "",
          prop: "recipient_store_user_name",
        },
        {
          id: 18,
          name: "签收时间",
          value: "",
          prop: "recipient_time_text",
        },
        {
          id: 19,
          name: "调拨备注",
          value: "",
          prop: "allot_remark",
        },
      ],
      arr2: [
        {
          id: 0,
          name: "调拨单号",
          value: "",
          prop: "allot_number",
        },
        {
          id: 1,
          name: "签收状态",
          value: "",
          prop: "allot_status_text",
        },
        {
          id: 2,
          name: "调拨数量",
          value: "",
          prop: "allot_total_num",
        },
        {
          id: 3,
          name: "调出门店",
          value: "",
          prop: "at_hierarchy_name",
        },
        {
          id: 4,
          name: "添加人",
          value: "",
          prop: "create_store_user_name",
        },
        {
          id: 5,
          name: "添加时间",
          value: "",
          prop: "create_time",
        },
        {
          id: 6,
          name: "调入仓库",
          value: "",
          prop: "to_warehouse_text",
        },
        {
          id: 7,
          name: "签收人员",
          value: "",
          prop: "audit_store_user_name",
        },
        {
          id: 8,
          name: "签收时间",
          value: "",
          prop: "audit_time_text",
        },
        {
          id: 9,
          name: "原因说明",
          value: "",
          prop: "recipient_content",
        },
      ],
      rowList1: [
        {
          label: "序号",
          prop: "index",
        },
        {
          label: "货品图片",
          prop: "goods_pic",
          isImg: true,
        },
        {
          label: "货号",
          prop: "goods_number",
          t_width: 200,
        },
        {
          label: "入库单号",
          prop: "entry_number",
          t_width: 220,
        },
        {
          label: "款号",
          prop: "goods_style_number",
        },
        {
          label: "品名",
          prop: "goods_name",
          t_width: 220,
        },
        {
          label: "品类",
          prop: "goods_type_name",
        },
        {
          prop: "warehouse_name",
          label: "仓库",
          t_width: 100,
        },
        {
          label: "产地",
          prop: "105",
        },
        {
          label: "品牌",
          prop: "103",
        },
        {
          label: "净金重",
          prop: "104",
        },
        {
          label: "总重",
          prop: "110",
        },
        {
          label: "成本价",
          prop: "cost_price",
        },
        {
          label: "标签价",
          prop: "sale_price",
        },
        {
          label: "回收价",
          prop: "recycle_price",
        },
        {
          label: "货品数量",
          prop: "total_num",
        },
        {
          label: "标签价",
          prop: "sale_price",
        },
        {
          label: "销售工费",
          prop: "184",
        },
        {
          label: "销售工费类型",
          prop: "185",
          t_width: 120,
        },
        {
          label: "款式",
          prop: "101",
        },
        {
          label: "材质成色",
          prop: "100",
        },
        {
          label: "主石重",
          prop: "130",
        },
        {
          label: "辅石1重量",
          prop: "133",
          t_width: 120,
        },
        {
          label: "证书号",
          prop: "112",
          t_width: 150,
        },
        {
          label: "入库时间",
          prop: "create_time",
          t_width: 160,
        },
      ],
      tableData1: {
        list: [],
        total: 0,
      },
      rowList2: [
        {
          label: "操作时间",
          prop: "create_time",
          width: 200,
        },
        {
          label: "操作记录",
          prop: "msg",
        },
        {
          label: "操作人",
          prop: "create_user_name",
          width: 200,
        },
      ],
      tableData2: {
        list: [],
        total: 0,
      },
    };
  },
  components: {
    Detail,
    Check,
    PrintConf,
    LocalPrint,
  },
  props: {
    showPrint: Boolean,
    allot_to: Number,
    activeName: String,
    allot_id: Number,
    title: String,
    propObj: {
      type: Object,
      default() {
        return {
          key: "",
          value: "",
        };
      },
    },
  },
  created() {
    const userInfo = storage.get("userInfo");
    this.userInfo = userInfo;
    this.getDetail();
    this.getDetailGoods();
    this.getLogList();
  },
  methods: {
    getAllotPrint(cond) {
      allotPrintReq({ ...cond })
        .then((res) => {
          if (res.code === 1) {
            res.data.list.forEach((item, index) => {
              item.index = index + 1;
            });
            res.data.top = [];
            const data = res.data.data;
            const arr1 = [
              { txt: "创建人", value: data.create_realname, width: "24%" },
              { txt: "创建时间", value: data.create_time, width: "24%" },
              { txt: "调拨单号", value: data.allot_number, width: "30%" },
            ];
            const arr2 = [
              { txt: "调出门店", value: data.at_short_name, width: "24%" },
              { txt: "调出仓库", value: data.h_warehouse_name, width: "24%" },
              { txt: "调入门店", value: data.to_short_name, width: "24%" },
              { txt: "调入仓库", value: data.to_warehouse_name, width: "24%" },
            ];
            res.data.bottom = [];
            const arr3 = [
              { txt: "备注", value: data.allot_remark, width: "100%" },
            ];
            const arr4 = [
              { txt: "审核状态", value: data.allot_status, width: "24%" },
              { txt: "审核人", value: data.audit_realname, width: "24%" },
              {
                txt: "审核时间",
                value: data.audit_time ? data.audit_time : "",
                width: "30%",
              },
            ];
            const arr5 = [
              { txt: "签收人", value: data.recipient_realname, width: "24%" },
              {
                txt: "签收时间",
                value: data.recipient_time ? data.recipient_time : "",
                width: "24%",
              },
            ];
            res.data.top.push(arr1, arr2);
            res.data.bottom.push(arr3, arr4, arr5);
            // 计算合计
            this.printData = res.data;
            const sum = {
              index: "合计",
            };
            const decimal = {};
            this.printData.list.forEach((item) => {
              this.printRows.forEach((row) => {
                if (row.is_sum) {
                  if (!sum[row.field_alias]) {
                    sum[row.field_alias] = 0;
                  }
                  if (item[row.field_alias]) {
                    sum[row.field_alias] += Number(item[row.field_alias]);
                    if (!decimal[row.field_alias]) {
                      const arr = String(item[row.field_alias]).split(".");
                      if (arr.length === 2) {
                        decimal[row.field_alias] = arr[1].length;
                      } else {
                        decimal[row.field_alias] = 0;
                      }
                    }
                  }
                }
              });
            });
            // 需要保留多少位小数
            Object.keys(sum).forEach((item) => {
              if (item !== "index") {
                sum[item] = sum[item].toFixed(decimal[item]);
              }
            });
            this.printData.list.push(sum);
            this.localPrintVisible = true;
          }
        })
    },
    handlePrintRow(rows) {
      this.printRows = rows;
    },
    handlePrint() {
      this.getAllotPrint({
        [this.propObj.key]: this.propObj.value,
      });
    },
    handleTransferCheck() {
      this.checkVisiable = true;
    },
    // 操作日志
    getLogList() {
      getLogListReq({
        [this.propObj.key]: this.propObj.value,
        page: this.page2,
        limit: this.page_num2,
      }).then((res) => {
        if (res.code === 1) {
          this.tableData2 = res.data;
        }
      });
    },
    handleSizeChange2(page_num) {
      this.page2 = 1;
      this.page_num2 = page_num;
      this.getLogList();
    },
    handleSizeChange(page_num) {
      this.page = 1;
      this.page_num = page_num;
      this.getDetailGoods();
    },
    getDetail() {
      this.checkVisiable = false;
      detailReq(this.propObj.value).then((res) => {
        if (res.code === 1) {
          if (res.data.allot_status === 40 || res.data.allot_status === 50) {
            // this.arr = [...this.arr2];
          }
          this.allot_status = res.data.allot_status;
          this.arr.forEach((item) => {
            item.value = res.data[item.prop] ? res.data[item.prop] : "--";
          });
        }
      });
    },
    getDetailGoods() {
      detailGoodsListReq({
        [this.propObj.key]: this.propObj.value,
        page: this.page,
        page_num: this.page_num,
      }).then((res) => {
        if (res.code === 1) {
          this.tableData1 = res.data;
        }
      });
    },
    goBack() {
      this.$emit("hide");
    },
    handlePage(page) {
      this.page = page;
      this.getDetailGoods();
    },
    handlePage2(page) {
      this.page2 = page;
      this.getLogList();
    },
  },
};
</script>
