<template>
  <div>
    <div
      class="content"
      v-show="!addVisiable && !detailVisiable && !examineVisiable"
      style="background: #f6f7fb"
    >
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane :label="label[0]" name="first">
          <div class="card" style="display: flex">
            <div class="left">
              <el-button
                style="margin-left: 10px; height: 40px"
                type="primary"
                @click="handleAdd"
                icon="el-icon-plus"
                >新增{{
                  userInfo.hierarchy_type_id === 30 ? "配货" : "调拨"
                }}单</el-button
              >
              <el-button
                type="primary"
                plain
                @click="handleExport"
                style="height: 40px;"
                :loading="exportLoading"
                icon="el-icon-upload2">导出</el-button>
            </div>
            <div class="line"></div>
            <div class="middle">
              <Cond
                @onCond="handleCond"
                function_code="allot"
                :tab="1"
                :mode="mode"
                v-if="activeName === 'first' && rowList.length"
              />
            </div>
            <div class="right" style="display: flex">
              <TableConf
                function_code="allot"
                @onRowList="handleRowList"
                :tab="1"
                :mode="mode"
              />
              <PrintConf
                function_code="allotExport"
                @onRowList="handlePrintRow"
              />
              <el-button
                @click="changeMode"
                style="margin-left: 10px; height: 40px"
                >切换版式：{{ mode === 1 ? "货品模式" : "单号模式" }}</el-button
              >
            </div>
          </div>
          <div class="total">
            <div class="total-right">
              <div class="total-right-item">
                总数量 <span>{{ data.total_num || 0 }}</span> 件
              </div>
              <div class="total-right-item">
                总净金重 <span>{{ data.gold_weight || "0.00" }}</span> g
              </div>
              <div class="total-right-item">
                总重 <span>{{ data.total_weight || "0.00" }}</span> g
              </div>
              <div class="total-right-item">
                总成本价 <span>{{ data.cost_price || "0.00" }}</span> 元
              </div>
              <div class="total-right-item">
                总标签价 <span>{{ data.total_sale_price || "0.00" }}</span> 元
              </div>
              <div class="total-right-item">
                总回收价 <span>{{ data.total_recycle_price || "0.00" }}</span> 元
              </div>
            </div>
          </div>
          <div
            class="table"
            v-if="rowList.length"
            style="border: 1px solid #ddd; border-radius: 6px; overflow: hidden"
          >
            <el-table
              :data="data.list"
              stripe
              :cell-class-name="getCellIndex"
              v-loading="loading"
              height="600"
              @row-dblclick="handleDalclick"
            >
              <template v-for="(item, index) in rowList">
                <el-table-column
                  v-if="item.filed_status"
                  align="center"
                  :key="index"
                  :prop="item.field_alias"
                  :label="item.field_text"
                  :width="item.t_width"
                  :filters="item.filed_status"
                  :filter-method="filterHandler"
                >
                  <template slot-scope="scope">
                    <template v-if="item.field_alias === 'goods_pic'">
                      <img
                        :src="scope.row.goods_pic"
                        v-if="scope.row.goods_pic"
                        class="image-slot"
                      />
                      <div slot="error" class="image-slot" v-else>
                        <img src="../../../../assets/images/no_img.png" />
                      </div>
                    </template>
                    <template v-else>
                      {{
                        scope.row[item.field_alias]
                          ? scope.row[item.field_alias]
                          : "--"
                      }}
                    </template>
                  </template>
                </el-table-column>
                <el-table-column
                  v-else
                  align="center"
                  :key="index"
                  :prop="item.field_alias"
                  :label="item.field_text"
                  :width="item.t_width"
                >
                  <template slot-scope="scope">
                    <template v-if="item.field_alias === 'goods_pic'">
                      <img
                        :src="scope.row.goods_pic"
                        v-if="scope.row.goods_pic"
                        class="image-slot"
                      />
                      <div slot="error" class="image-slot" v-else>
                        <img src="../../../../assets/images/no_img.png" />
                      </div>
                    </template>
                    <template v-else>
                      {{
                        scope.row[item.field_alias]
                          ? scope.row[item.field_alias]
                          : "--"
                      }}
                    </template>
                  </template>
                </el-table-column>
              </template>
              <el-table-column
                align="center"
                fixed="right"
                prop="operation"
                label="操作"
                width="180"
              >
                <template slot-scope="scope">
                  <div class="options">
                    <el-select
                      v-model="scope.row.select"
                      placeholder="请选择"
                      @change="handleChange($event, scope.row)"
                    >
                      <el-option label="详情" :value="1"></el-option>
                      <el-option
                        label="审核"
                        :value="2"
                        v-if="scope.row.allot_status === 10"
                      ></el-option>
                      <el-option
                        label="查看拒绝原因"
                        :value="3"
                        v-if="
                          scope.row.allot_status === 20 ||
                          scope.row.allot_status === 50
                        "
                      ></el-option>
                      <el-option
                        label="编辑"
                        :value="4"
                        v-if="scope.row.allot_status === 20 || noSockIds.includes(scope.row.allot_id) === true"
                      ></el-option>
                      <el-option
                        label="签收"
                        :value="5"
                        v-if="scope.row.allot_status === 50"
                      ></el-option>
                      <el-option label="打印" :value="6"></el-option>
                      <el-option label="打印标签" :value="7" v-if="scope.row.allot_status === 40"></el-option>
                      <el-option
                        label="解锁"
                        v-if="
                          scope.row.allot_status === 40 &&
                          noSockIds.includes(scope.row.allot_id) === false
                        "
                        :value="20"
                      ></el-option>
                      <!-- <el-option
                        label="锁定"
                        :value="21"
                        v-if="
                          noSockIds.includes(scope.row.allot_id) === true
                        "
                      ></el-option> -->
                      <el-option
                        label="删除"
                        :value="22"
                        v-if="
                          noSockIds.includes(scope.row.allot_id) === true
                        "
                      ></el-option>
                      <el-option label="撤销" :value="23" v-if="scope.row.allot_status === 30"></el-option>
                      <el-option label="删除" :value="24" v-if="scope.row.allot_status === 20 || scope.row.allot_status === 70"></el-option>
                    </el-select>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="pagina">
            <el-pagination
              :current-page="page1"
              background
              layout="total, sizes, prev, pager, next, jumper"
              :total="data.total"
              :page-size="$store.state.pageSizes[0]"
              :page-sizes="$store.state.pageSizes"
              @current-change="handleCurrentChange"
              @size-change="handleSizeChange"
            ></el-pagination>
          </div>
        </el-tab-pane>
        <el-tab-pane :label="label[1]" name="second">
          <div class="card" style="display: flex">
            <div class="left">
              <el-button
                type="primary"
                plain
                @click="handleExport"
                icon="el-icon-upload2"
                :loading="exportLoading"
                style="height: 40px;"
                >导出</el-button
              >
            </div>
            <div class="middle">
              <Cond
                @onCond="handleCond"
                function_code="allot"
                :tab="2"
                :mode="mode2"
                v-if="activeName === 'second' && rowInList.length"
              />
            </div>
            <div class="right" style="display: flex">
              <TableConf
                function_code="allot"
                @onRowList="handleRowList2"
                :tab="2"
                :mode="mode2"
              />
              <PrintConf
                function_code="allotExport"
                @onRowList="handlePrintRow"
              />
              <el-button @click="changeMode" style="margin-left: 10px; height: 40px;"
                >切换版式：{{
                  mode2 === 1 ? "货品模式" : "单号模式"
                }}</el-button
              >
            </div>
          </div>
          <div class="total">
            <div class="total-right">
              <div class="total-right">
              <div class="total-right-item">
                总数量 <span>{{ tableData.total_num || 0 }}</span> 件
              </div>
              <div class="total-right-item">
                总净金重 <span>{{ tableData.gold_weight || "0.00" }}</span> g
              </div>
              <div class="total-right-item">
                总重 <span>{{ tableData.total_weight || "0.00" }}</span> g
              </div>
              <div class="total-right-item">
                总成本价 <span>{{ tableData.cost_price || "0.00" }}</span> 元
              </div>
              <div class="total-right-item">
                总标签价 <span>{{ tableData.total_sale_price || "0.00" }}</span> 元
              </div>
              <div class="total-right-item">
                总回收价 <span>{{ tableData.total_recycle_price || "0.00" }}</span> 元
              </div>
            </div>
            </div>
          </div>
          <div
            class="table"
            v-if="rowInList.length"
            style="border: 1px solid #ddd; border-radius: 6px; overflow: hidden"
          >
            <el-table
              :data="tableData.list"
              stripe
              :cell-class-name="getCellIndex"
              v-loading="loading2"
              height="600"
              @row-dblclick="handleDalclick"
            >
              <template v-for="(item, index) in rowInList">
                <el-table-column
                  v-if="item.filed_status"
                  align="center"
                  :key="index"
                  :prop="item.field_alias"
                  :label="item.field_text"
                  :width="item.t_width"
                  :filters="item.filed_status"
                  :filter-method="filterHandler"
                >
                  <template slot-scope="scope">
                    <template v-if="item.field_alias === 'goods_pic'">
                      <img
                        :src="scope.row.goods_pic"
                        v-if="scope.row.goods_pic"
                        class="image-slot"
                      />
                      <div slot="error" class="image-slot" v-else>
                        <img src="../../../../assets/images/no_img.png" />
                      </div>
                    </template>
                    <template v-else>
                      {{
                        scope.row[item.field_alias]
                          ? scope.row[item.field_alias]
                          : "--"
                      }}
                    </template>
                  </template>
                </el-table-column>
                <el-table-column
                  v-else
                  align="center"
                  :key="index"
                  :prop="item.field_alias"
                  :label="item.field_text"
                  :width="item.t_width"
                >
                  <template slot-scope="scope">
                    <template v-if="item.field_alias === 'goods_pic'">
                      <img
                        :src="scope.row.goods_pic"
                        v-if="scope.row.goods_pic"
                        class="image-slot"
                      />
                      <div slot="error" class="image-slot" v-else>
                        <img src="../../../../assets/images/no_img.png" />
                      </div>
                    </template>
                    <template v-else>
                      {{
                        scope.row[item.field_alias]
                          ? scope.row[item.field_alias]
                          : "--"
                      }}
                    </template>
                  </template>
                </el-table-column>
              </template>
              <el-table-column
                align="center"
                fixed="right"
                prop="operation"
                label="操作"
                width="180"
              >
                <template slot-scope="scope">
                  <div class="options">
                    <el-select
                      v-model="scope.row.select"
                      placeholder="请选择"
                      @change="handleChange($event, scope.row)"
                    >
                      <el-option label="详情" :value="1"></el-option>
                      <el-option
                        label="签收"
                        :value="2"
                        v-if="scope.row.allot_status === 30"
                      ></el-option>
                      <el-option
                        label="查看拒绝原因"
                        :value="3"
                        v-if="
                          scope.row.allot_status === 20 ||
                          scope.row.allot_status === 50
                        "
                      ></el-option>
                      <el-option label="打印" :value="6"></el-option>
                      <el-option label="打印标签" :value="7" v-if="scope.row.allot_status === 40"></el-option>
                    </el-select>
                  </div>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div class="pagina">
            <el-pagination
              :current-page="page2"
              background
              @size-change="handleSizeChange"
              layout="total, sizes, prev, pager, next, jumper"
              :total="tableData.total"
              :page-size="$store.state.pageSizes[0]"
              :page-sizes="$store.state.pageSizes"
              @current-change="handleCurrentChange2"
            ></el-pagination>
          </div>
          <el-dialog
            :visible.sync="tableVisiable2"
            title="设置表头"
            width="30%"
            :destroy-on-close="true"
            :before-close="cancelTable"
          >
            <div class="table">
              <el-table
                :data="rowInList"
                stripe
                @cell-click="hanldeCellClick"
                :cell-class-name="getCellIndex"
              >
                <el-table-column align="center" prop="index" label="排序">
                  <template slot-scope="scope">
                    <el-button
                      size="mini"
                      icon="el-icon-top"
                      v-if="scope.row.index !== 0"
                    ></el-button>
                    <el-button
                      size="mini"
                      icon="el-icon-bottom"
                      v-if="scope.row.index !== 9"
                    ></el-button>
                  </template>
                </el-table-column>
                <el-table-column
                  align="center"
                  prop="label"
                  label="表头字段"
                ></el-table-column>
                <el-table-column
                  align="center"
                  prop="width"
                  label="宽度(px)"
                  column-key="3"
                >
                  <template slot-scope="scope">
                    <el-input
                      v-model="scope.row.width"
                      v-show="
                        coordinate.column === scope.column.index &&
                        coordinate.row === scope.row.index
                      "
                    >
                    </el-input>
                    <span
                      v-show="
                        scope.row.index != coordinate.row ||
                        scope.column.index != coordinate.column
                      "
                    >
                      {{ scope.row.width }}
                    </span>
                  </template>
                </el-table-column>
                <el-table-column align="center" prop="show" label="是否显示">
                  <template slot-scope="scope">
                    <el-switch v-model="scope.row.show"></el-switch>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-dialog>
        </el-tab-pane>
      </el-tabs>
    </div>
    <LocalPrint
      :show="false"
      :printRows="printRows"
      :printData="printData"
      :title="title"
      :dialogVisible="localPrintVisible"
      @onClose="localPrintVisible = false"
    />
    <el-dialog
      title="拒绝原因"
      :visible.sync="reasonVisible"
      :before-close="handleClose"
      width="30%"
    >
      <span>{{ currRow.audit_content || currRow.recipient_content }}</span>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleClose">确 定</el-button>
      </span>
    </el-dialog>
    <Add
      :allot_id="currRow.allot_id"
      :warehouse_id="form.warehouse_id"
      :allot_state="1"
      v-if="addVisiable"
      @hide="hanldeHide"
      @getList="getAllList"
      :row="currRow"
    />
    <Detail
      v-if="detailVisiable"
      :propObj="propObj"
      :activeName="activeName"
      @hide="handleHideDetail"
      :allot_to="allot_to"
      :showPrint="true"
      :title="title"
    />
    <Examine
      v-if="examineVisiable"
      :allot_id="currRow.allot_id"
      @hide="handleExamineHide"
      :activeName="activeName"
      @getList="getAllList"
      :reAduit="reAduit"
    />
    <el-dialog
      title="请先选择仓库"
      :before-close="handleCloseWareHouse"
      :visible.sync="warehouseVisible"
      :destroy-on-close="true"
      width="30%"
    >
      <el-form :model="form" :rules="rules" ref="ruleForm">
        <el-form-item
          label="仓库"
          label-width="100px"
          required
          prop="warehouse_id"
        >
          <el-select v-model="form.warehouse_id" clearable>
            <template v-for="item in warehouseList">
              <el-option
                :label="item.warehouse_name"
                :value="item.warehouse_id"
                :key="item.warehouse_id"
              ></el-option>
            </template>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleCloseWareHouse">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      :visible.sync="printVisible"
      :before-close="beforeClose"
      title="打印标签"
      :destroy-on-close="true"
    >
      <Print :type="50" print_bill_type="allot" :order_id="currRow.allot_id" v-if="printVisible" />
    </el-dialog>
  </div>
</template>

<script>
import storage from "good-storage";
import Add from "./Add.vue";
import Detail from "./Detail.vue";
import Examine from "./Examine.vue";
import moment from "moment";
import Print from "@/components/print/Index.vue";
import { getIsLock } from '@/api/monthSettle';
import {
  listReq,
  exportReq,
  allotPrintReq,
  resetDeleteReq,
  recallReq,
  deleteReq,
} from "@/api/goods/goodsTransfer/list";
import { listReq as getWarehouseListReq } from "@/api/goods/goodsStore/list";
import Cond from "@/components/cond/Index.vue";
import TableConf from "@/components/tableConf/Index.vue";
import PrintConf from "@/components/printConf/Index.vue";
import LocalPrint from "@/components/localPrint/Index.vue";

export default {
  data() {
    return {
      printVisible: false,
      noSockIds: [],
      title: "调拨出库单",
      localPrintVisible: false,
      printData: {},
      printRows: [], // 打印的行
      form: {},
      rules: {
        warehouse_id: [{ required: true, message: "请选择仓库" }],
      },
      warehouseList: [],
      warehouseVisible: false,
      mode2: 1,
      mode: 1,
      allot_to: 0,
      exportLoading: false,
      reAduit: false,
      reasonVisible: false,
      propObj: {
        key: "",
        value: "",
      },
      loading: false,
      loading2: false,
      currRow: {},
      data: [],
      userInfo: {},
      label: ["门店调出", "调入本店"],
      activeName: "first",
      examineVisiable: false,
      detailVisiable: false,
      addVisiable: false,
      total: 0,
      tableVisiable2: false,
      tableVisiable: false,
      page1: 1,
      page_num1: this.$store.state.pageSizes[0],
      page2: 1,
      page_num2: this.$store.state.pageSizes[0],
      cond: {
        // allot_to: 0,
        // page: 1,
        // page_num: 15,
      },
      cond2: {
        // allot_to: 1,
        // page: 1,
        // page_num: 15,
      },
      tableData: [],
      rowList: [],
      rowInList: [],
      coordinate: { column: -1, row: -1 },
    };
  },
  components: {
    Add,
    Detail,
    Examine,
    Cond,
    TableConf,
    PrintConf,
    LocalPrint,
    Print,
  },
  created() {
    const userInfo = storage.get("userInfo");
    this.userInfo = userInfo;
    this.getWarehouseList();
    this.currRow = {};
    // const { hierarchy_type_id } = userInfo;
    // const rowList = [...this.rowList];
    // const rowInList = [...this.rowInList];
    // if (hierarchy_type_id === 30) {
    //   this.label = ["配货", "调入公司"];
    //   rowList[1].label = "配货单号";
    //   rowInList[1].label = "配货单号";
    // } else {
    //   this.label = ["门店调出", "调入本店"];
    //   rowList[1].label = "调拨单号";
    //   rowInList[1].label = "调拨单号";
    // }
    // this.rowList = rowList;
    // this.rowInList = rowInList;
  },
  mounted() {
    this.getList();
    this.getList2();
    if (this.$route.params.type) {
      if (this.$route.params.type === "detail") {
        this.detailVisiable = true;
        this.propObj = {
          key: "allot_id",
          value: this.$route.params.key,
        };
      }
    }
  },
  methods: {
    beforeClose() {
      this.currRow.select = "";
      this.printVisible = false;
    },
    getAllotPrint(cond) {
      allotPrintReq({ ...cond })
        .then((res) => {
          if (res.code === 1) {
            this.currRow.select = "";
            this.currRow = {};
            res.data.list.forEach((item, index) => {
              item.index = index + 1;
            });
            res.data.top = [];
            const data = res.data.data;
            const arr1 = [
              { txt: "创建人", value: data.create_realname, width: "24%" },
              { txt: "创建时间", value: data.create_time, width: "24%" },
              { txt: "调拨单号", value: data.allot_number, width: "30%" },
            ];
            const arr2 = [
              { txt: "调出门店", value: data.at_short_name, width: "24%" },
              { txt: "调出仓库", value: data.h_warehouse_name, width: "24%" },
              { txt: "调入门店", value: data.to_short_name, width: "24%" },
              { txt: "调入仓库", value: data.to_warehouse_name, width: "24%" },
            ];
            res.data.bottom = [];
            const arr3 = [
              { txt: "备注", value: data.allot_remark, width: "100%" },
            ];
            const arr4 = [
              { txt: "审核状态", value: data.allot_status, width: "24%" },
              { txt: "审核人", value: data.audit_realname, width: "24%" },
              {
                txt: "审核时间",
                value: data.audit_time ? data.audit_time : "",
                width: "30%",
              },
            ];
            const arr5 = [
              { txt: "签收人", value: data.recipient_realname, width: "24%" },
              {
                txt: "签收时间",
                value: data.recipient_time ? data.recipient_time : "",
                width: "24%",
              },
            ];
            res.data.top.push(arr1, arr2);
            res.data.bottom.push(arr3, arr4, arr5);
            // 计算合计
            this.printData = res.data;
            const sum = {
              index: "合计",
            };
            const decimal = {};
            this.printData.list.forEach((item) => {
              this.printRows.forEach((row) => {
                if (row.is_sum) {
                  if (!sum[row.field_alias]) {
                    sum[row.field_alias] = 0;
                  }
                  if (item[row.field_alias]) {
                    sum[row.field_alias] += Number(item[row.field_alias]);
                    if (!decimal[row.field_alias]) {
                      const arr = String(item[row.field_alias]).split(".");
                      if (arr.length === 2) {
                        decimal[row.field_alias] = arr[1].length;
                      } else {
                        decimal[row.field_alias] = 0;
                      }
                    }
                  }
                }
              });
            });
            // 需要保留多少位小数
            Object.keys(sum).forEach((item) => {
              if (item !== "index") {
                sum[item] = sum[item].toFixed(decimal[item]);
              }
            });
            this.printData.list.push(sum);
            this.localPrintVisible = true;
          }
        })
        .catch(() => {
          this.currRow.select = "";
        });
    },
    handlePrintRow(rows) {
      this.printRows = rows;
    },
    handleCloseWareHouse() {
      this.warehouseVisible = false;
      this.form = {};
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // 成功的操作
          this.warehouseVisible = false;
          this.addVisiable = true;
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    getWarehouseList() {
      getWarehouseListReq({ page: 1, limit: 999999 }).then((res) => {
        if (res.code === 1) {
          res.data.list.unshift({
            warehouse_id: 0,
            warehouse_name: '全部',
          })
          this.warehouseList = res.data.list;
        }
      });
    },
    changeMode() {
      if (this.activeName === "first") {
        this.rowList = [];
        this.mode === 1 ? (this.mode = 2) : (this.mode = 1);
      } else {
        this.rowInList = [];
        this.mode2 === 1 ? (this.mode2 = 2) : (this.mode2 = 1);
      }
      this.page1 = 1;
      this.getList();
      this.page2 = 1;
      this.getList2();
    },
    handleDalclick(row) {
      this.currRow = row;
      this.propObj = {
        key: "allot_id",
        value: row.allot_id,
      };
      this.detailVisiable = true;
      this.cancelTable();
    },
    handleExamineHide(reAduit) {
      if (reAduit) {
        this.reAduit = false;
        this.activeName = "first";
      }
      this.examineVisiable = false;
      this.currRow = {};
      this.getList();
      this.getList2();
    },
    handleClick() {
      if (this.activeName === "first") {
        this.allot_to = 0;
        this.title = "调拨出库单";
        this.cond = {};
        this.getList();
      } else {
        this.allot_to = 1;
        this.title = "调拨入库单";
        this.cond2 = {};
        this.getList2();
      }
    },
    // 改变分页条数
    handleSizeChange(page_num) {
      if (this.activeName === "first") {
        this.page_num1 = page_num;
        this.getList();
      } else {
        this.page_num2 = page_num;
        this.getList2();
      }
    },
    handleClose() {
      this.dialogVisible = false;
      this.reasonVisible = false;
      this.form = {};
      this.cancelTable();
    },
    handleHideDetail() {
      this.detailVisiable = false;
      this.currRow = {};
    },
    hanldeHide() {
      this.form = {
        warehouse_id: '',
      };
      // this.$refs.ruleForm.resetFields();
      this.addVisiable = false;
      this.currRow.select = '';
      this.currRow = {};
    },
    filterHandler(value, row, column) {
      let { property } = column;
      if (property === "allot_status_text") {
        property = "allot_status";
      }
      return row[property] === value;
    },
    handleCond(cond) {
      if (this.activeName === "first") {
        this.page1 = 1;
        this.cond = { ...cond };
        this.getList();
      } else {
        this.page2 = 1;
        this.cond2 = { ...cond };
        this.getList2();
      }
    },
    // 处理表格数据
    handleRowList(rowList) {
      this.rowList = rowList;
    },
    handleRowList2(rowList) {
      this.rowInList = rowList;
    },
    getAllList() {
      this.getList();
      this.getList2();
    },
    getList() {
      this.loading = true;
      listReq({
        ...this.cond,
        allot_to: 0,
        page: this.page1,
        page_num: this.page_num1,
        mode: this.mode,
      })
        .then((res) => {
          if (res.code === 1) {
            this.loading = false;
            // res.data.list.forEach((item) => {
            //   item.create_time && (item.create_time = moment(item.create_time * 1000).format('yyyy-MM-DD hh:mm:ss'));
            //   item.audit_time_text && (item.audit_time_text = moment(item.audit_time_text * 1000).format('yyyy-MM-DD hh:mm:ss'));
            // });
            this.data = res.data;
          }
        })
        .catch(() => {
          this.loading = false;
        });
    },
    getList2() {
      this.loading2 = true;
      listReq({
        ...this.cond2,
        allot_to: 1,
        page: this.page2,
        page_num: this.page_num2,
        mode: this.mode,
      })
        .then((res) => {
          if (res.code === 1) {
            this.loading2 = false;
            // res.data.list.forEach((item) => {
            //   item.create_time && (item.create_time = moment(item.create_time * 1000).format('yyyy-MM-DD hh:mm:ss'));
            //   item.audit_time_text && (item.audit_time_text = moment(item.audit_time_text * 1000).format('yyyy-MM-DD hh:mm:ss'));
            // });
            this.tableData = res.data;
          }
        })
        .catch(() => {
          this.loading2 = false;
        });
    },
    getCellIndex({ row, column, rowIndex, columnIndex }) {
      row.index = rowIndex + 1;
      column.index = columnIndex + 1;
    },
    hanldeCellClick(row, column) {
      const coordinate = { ...this.coordinate };
      coordinate.row = row.index;
      coordinate.column = column.index;
      this.coordinate = coordinate;
    },
    fetchData() {},
    /*
     *  导出
     */
    //  function_code   tab   mode
    handleExport() {
      let allot_to;
      if (this.activeName === "first") {
        allot_to = 0;
      } else {
        allot_to = 1;
      }
      const data = {
        function_code: 'allot',
        tab: this.activeName === "first" ? 1 : 2,
        mode: this.mode,
        allot_to,
        ...this.cond,
      };
      console.log(this.mode)
      this.exportLoading = true;
      exportReq(data)
        .then((res) => {
          this.exportLoading = false;
          const blob = new Blob([res], { type: "application/vnd.ms-excel" });
          const a = document.createElement("a");
          a.setAttribute("download", `${allot_to === 0 ? '调出列表' : '调入列表'}.csv`);
          a.href = URL.createObjectURL(blob);
          a.click();
        })
        .catch(() => {
          this.exportLoading = false;
        });
    },
    /*
     *  新增入库
     */
    handleAdd() {
      this.warehouseVisible = true;
    },
    /*
     *  检索
     */
    handleChange(val, row) {
      this.currRow = row;
      this.propObj = {
        key: "allot_id",
        value: row.allot_id,
      };
      if (val === 1) {
        this.detailVisiable = true;
        this.cancelTable();
      }
      if (val === 2) {
        this.examineVisiable = true;
        this.cancelTable();
      }
      if (val === 3) {
        this.reasonVisible = true;
      }
      // 编辑
      if (val === 4) {
        this.addVisiable = true;
        const form = { ...this.form };
        form.warehouse_id = row.at_warehouse_id;
        this.form = form;
        this.cancelTable();
      }
      if (val === 5) {
        this.activeName = "second";
        this.examineVisiable = true;
        this.reAduit = true;
        this.cancelTable();
      }
      if (val === 6) {
        this.getAllotPrint({
          allot_id: row.allot_id,
        });
      }
      if (val === 7) {
        this.printVisible = true;
      }
      if (val === 20) {
        // 此处请求接口待定
        getIsLock({
          source: 'allot',
          primary_key_id: row.allot_id,
        })
          .then((res) => {
            if (res.code === 1) {
              // this.addVisiable = true;
              this.$message.success('解锁成功');
              row.select = '';
              this.currRow = {};
              this.noSockIds.push(row.allot_id);
            }
          })
          .catch(() => {
            row.select = '';
            this.currRow = {};
          });
      }
      // 反确认删除
      if (val === 22) {
        this.$confirm('确定要删除该调拨单？删除后将无法恢复，货品信息将恢复到修改前。', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            row.select = '';
            resetDeleteReq({
              allot_id: row.allot_id,
            })
              .then((res) => {
                if (res.code === 1) {
                  this.currRow = {};
                  this.getList();
                }
              });
          })
          .catch(() => {
            row.select = '';
            this.currRow = {};
          })
      }
      if (val === 23) {
        this.$confirm('确定要撤销该调拨单？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            recallReq({
              allot_id: row.allot_id,
            })
              .then((res) => {
                if (res.code === 1) {
                  this.$message.success('操作成功');
                  this.getList();
                  this.currRow = {};
                }
              });
          })
          .catch(() => {
            row.select = '';
            this.currRow = {};
          });
      }
      // 被驳回删除
      if (val === 24) {
        this.$confirm('确定要删除该调拨单？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            deleteReq({
              allot_id: row.allot_id,
            })
              .then((res) => {
                if (res.code === 1) {
                  this.$message.success('操作成功');
                  this.getList();
                  this.currRow = {};
                }
              });
          })
          .catch(() => {
            row.select = '';
            this.currRow = {};
          });
      }
    },
    /*
     *  分页操作
     */
    handleCurrentChange(page) {
      this.page1 = page;
      this.getList();
    },
    handleCurrentChange2(page) {
      this.page2 = page;
      this.getList2();
    },
    /*
     *  关闭列表配置弹窗
     */
    cancelTable() {
      const coordinate = { ...this.coordinate };
      coordinate.row = -1;
      coordinate.column = -1;
      this.coordinate = coordinate;
      this.tableVisiable = false;
      this.tableVisiable2 = false;
      this.currRow.select = "";
    },
    /*
     *  关闭选择入库类型弹窗
     */
    cancelAdd() {
      this.addVisiable = false;
    },
  },
};
</script>

<style lang="less" scoped>
/deep/ .image-slot {
  width: 54px;
  height: 54px;
  margin: 5px auto;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 3px;
  }
}
.total {
  display: flex;
  justify-content: space-between;
  line-height: 42px;
  // padding: 0 20px;
  // background: linear-gradient(0deg, #f3f9ff, #ffffff);
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  background: white;
  margin-bottom: 10px;
  border-radius: 0px 0px 6px 6px;
  &-left {
    // flex-shrink: 0;
  }
  &-right {
    display: flex;
    flex: 1;
    &-item {
      font-size: 15px;
      // padding: 0 10px;
      width: 15%;
      // border-right: 1px solid #ddd;
      text-align: center;
      color: #909399;
      span {
        font-size: 16px;
        color: #e7541e;
      }
    }
  }
}
/deep/ .el-tabs__nav-wrap::after {
  height: 1px;
}
/deep/ .el-tabs__header {
  margin: 0 !important;
  padding-left: 50px;
  background: white;
}
/deep/ .tabs {
  padding: 0 !important;
}
/deep/ .el-tabs__content {
}
/deep/ .el-tabs__item {
  font-size: 14px;
  height: 50px;
  line-height: 50px;
}
.form {
  /deep/ .el-form-item {
    margin-bottom: 0px;
  }
}
.left {
  display: flex;
}
.middle {
  flex: 1;
}
</style>
