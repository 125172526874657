import { post } from '@/utils/request';

const createReq = (params) => post({
  url: '/store/goods.Allot/create',
  data: {
    ...params,
  },
});

// 调拨反确认提交
const resetReq = (params) => post({
  url: '/store/goods.Allot/reset',
  data: {
    ...params,
  },
});

export {
  createReq,
  resetReq,
};
